<template>
  <div class="device-resident">
    <HeaderMenu />
    <div class="container-fluid py-4">
      <div
        class="
          d-flex
          flex-column flex-sm-row
          align-items-center
          justify-content-between
        "
      >
        <h1 class="h5 fw-bold mb-3">{{ $t("__networkManagement") }}</h1>
        <div class="d-inline-flex flex-wrap">
          <button
            type="button"
            class="btn btn-primary me-3 mb-3 text-nowrap rounded-pill"
            @click="addModal"
          >
            {{ $t('__add')}}
          </button>
          <form @submit.prevent="searchNetwork">
            <div class="input-group rounded-pill mb-3">
              <input
                v-model="search"
                type="text"
                class="form-control rounded-pill"
                :placeholder="$t('__searchNetworkName')"
                aria-label="search"
                aria-describedby="search"
              />
              <button
                type="submit"
                class="btn btn-link px-2 text-dark"
                id="search"
              >
                <i class="bi bi-search"></i>
              </button>
              <button
                v-if="filter"
                type="button"
                class="btn btn-primary px-3 ms-1 rounded-pill"
                id="clearSearch"
                @click="clearSearch"
              >
                {{ $t("__clearSearch") }}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <b-table
            responsive
            striped
            borderless
            head-variant="light"
            :empty-text="$t('__notData')"
            :empty-filtered-text="$t('__notFindDevice')"
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
          >
            <template v-slot:head()="scope">
              <div class="text-nowrap">
                {{ scope.label }}
              </div>
            </template>
            <template v-slot:cell(devices)="data">
              <span
                v-for="(item, index) in data.item.devices"
                :key="'devices' + item.id"
                >{{ item.serial_id }}
                <span v-if="index < data.item.devices.length - 1">、</span>
              </span>
            </template>
            <template v-slot:cell(active)="data">
              <div class="d-flex">
                <button
                  v-if="data.item && !data.item.devices.length"
                  @click="editModal(data.item.id)"
                  type="button"
                  class="btn btn-link px-1 text-dark"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="$t('__edit')"
                >
                  <i class="bi bi-pencil mx-1"></i>
                </button>
                <button
                  v-if="data.item && !data.item.devices.length"
                  type="button"
                  href=""
                  class="btn btn-link px-1 text-danger"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="$t('__delete')"
                  @click="deleteAlert(data.item.id)"
                >
                  <i class="bi bi-trash"></i>
                </button>
              </div>
            </template>
          </b-table>
        </div>
      </div>
      <div class="row align-items-center mt-3" v-if="totalRows">
        <div class="col-sm col-12 text-right text-sm-left">
          {{ $t('__paginationInfo', {
            startNub: currentPage == 1 ? 1 : perPage * (currentPage - 1) + 1,
            entNub: currentPage == 1
              ? totalRows > perPage
                ? perPage
                : totalRows
              : perPage * (currentPage - 1) + perPage > totalRows
              ? totalRows
              : perPage * (currentPage - 1) + perPage ,
            sum: totalRows
            }) }}
        </div>
        <div class="col-sm col-12">
          <b-pagination
            v-if="totalRows > perPage"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            first-number
            last-number
            class="mb-sm-0 mb-3 mt-sm-0 mt-3 cms_main"
          ></b-pagination>
        </div>
      </div>
    </div>
    <div class="modal fade cms-style" tabindex="-1" id="modal" ref="modal">
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="submitForm">
            <div class="modal-header">
              <h5 class="modal-title fw-medium">
                {{ $t("__networkManagementSettings") }}
              </h5>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="mb-3">
                <label class="form-label fw-medium">{{ $t("__networkName") }}</label>
                <input v-model="form.name" type="text" class="form-control" required/>
              </div>
              <div class="mb-3">
                <label class="form-label fw-medium">SSID</label>
                <input v-model="form.ssid" type="text" class="form-control" required/>
              </div>
              <div class="mb-3">
                <label class="form-label fw-medium">{{ $t("__password") }}</label>
                <div class="input-group">
                  <input
                    v-model="form.password"
                    :type="show_password ? 'text' : 'password' "
                    class="form-control"
                  />
                  <span class="input-group-text show-password bg-white border-0" role="button" @click="showedPassword">
                    <i class="bi" :class="show_password ? 'bi-eye-slash-fill' : 'bi-eye-fill'"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-outline-primary rounded-pill"
                data-bs-dismiss="modal"
              >
                {{ $t("__cancel") }}
              </button>
              <button type="submit" class="btn btn-primary rounded-pill">
                {{ $t("__save") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="deleteModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      ref="deleteModal"
    >
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header justify-content-center">
            <h5 class="modal-title">{{ $t("__deleteNetwork") }}</h5>
          </div>
          <div class="modal-body text-center">
            <p class="mb-0">{{ $t("__deleteNetworkInfo") }}</p>
            <p class="mb-0 fs-5 py-2 fw-medium">【{{ form.name }}】</p>
            <p class="mb-0">{{ $t("__network") }}</p>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-outline-secondary rounded-pill"
              data-bs-dismiss="modal"
            >
              {{ $t("__cancel") }}
            </button>
            <button
              @click="deleteNetwork(edit_id)"
              type="button"
              class="btn btn-primary rounded-pill"
            >
              {{ $t("__confirm") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderMenu from '@/components/layout/HeaderMenu.vue'
import { Modal, Tooltip } from 'bootstrap'
import i18n from '@/lang/lang.js'
import { network } from '@/http/api/network.js'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'NetworkManagement',
  components: { HeaderMenu },
  data () {
    return {
      fields: [
        {
          key: 'name',
          label: i18n.t('__networkName'),
          class: 'align-middle',
          thStyle: 'width:120px'
        },
        {
          key: 'devices',
          label: i18n.t('__usedDeviceSerialNumber'),
          class: 'align-middle'
        },
        {
          key: 'active',
          label: i18n.t('__active'),
          tdClass: 'align-middle',
          class: 'align-middle',
          thStyle: 'width: 100px'
        }
      ],
      items: null,
      filter: null,
      filterOn: ['name'],
      search: null,
      bootstrap_tooltip: null,
      currentPage: 1,
      perPage: 10,
      is_edit: false,
      edit_id: null,
      bootstrap_modal: null,
      form: {
        ssid: null,
        name: null,
        password: ''
      },
      show_password: false
    }
  },
  computed: {
    ...mapState(['token', 'userInfo']),
    totalRows () {
      return this.items ? this.items.length : null
    }
  },
  watch: {
    item (data) {
      this.totalRows = data.length
      this.initBootstrapTooltip()
    },
    userInfo () {
      this.getNetwork()
    }
  },
  methods: {
    ...mapMutations([
      'LoadingText',
      'Loading',
      'Loaded',
      'AlertsInfo',
      'Alerted'
    ]),
    showedPassword () {
      this.show_password = !this.show_password
    },
    getNetwork () {
      const vm = this
      vm.Loading()
      network.get('', vm.token).then((res) => {
        if (res.status <= 201) {
          const data = res.data.data
          vm.items = data
        } else {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errors.toString()
          })
          vm.Alerted()
        }
        vm.Loaded()
      })
    },
    searchNetwork () {
      this.filter = this.search
    },
    clearSearch () {
      this.filter = null
      this.search = null
    },
    addModal () {
      this.is_edit = false
      this.form.ssid = null
      this.form.name = null
      this.form.password = ''
      this.$nextTick(function () {
        this.bootstrap_modal = new Modal(this.$refs.modal)
        this.bootstrap_modal.show()
      })
    },
    editModal (id) {
      this.is_edit = true
      this.edit_id = id
      this.items.forEach((ele) => {
        if (ele.id === id) {
          this.form.ssid = ele.ssid
          this.form.name = ele.name
          this.form.password = ele.password
        }
      })
      this.$nextTick(function () {
        this.bootstrap_modal = new Modal(this.$refs.modal)
        this.bootstrap_modal.show()
      })
    },
    submitForm () {
      const vm = this
      if (vm.is_edit) {
        network.update(vm.edit_id, vm.form, vm.token).then((res) => {
          if (res.status <= 201 && res.data.status === 'success') {
            vm.getNetwork()
            vm.AlertsInfo({
              state: 'success',
              title: i18n.t('__success'),
              info: i18n.t('__editSuccess')
            })
            vm.Alerted()
            vm.bootstrap_modal.hide()
          } else {
            vm.AlertsInfo({
              state: 'error',
              title: i18n.t('__error'),
              info: res.data.errMsg.toString()
            })
            vm.Alerted()
          }
          vm.Loaded()
        })
      } else {
        network.create(vm.form, vm.token).then((res) => {
          if (res.status <= 201 && res.data.status === 'success') {
            vm.getNetwork()
            vm.AlertsInfo({
              state: 'success',
              title: i18n.t('__success'),
              info: i18n.t('__addSuccess')
            })
            vm.Alerted()
            vm.bootstrap_modal.hide()
          } else {
            vm.AlertsInfo({
              state: 'error',
              title: i18n.t('__error'),
              info: res.data.errMsg.toString()
            })
            vm.Alerted()
          }
          vm.Loaded()
        })
      }
    },
    deleteAlert (id) {
      const vm = this
      vm.edit_id = id
      vm.items.forEach((ele) => {
        if (ele.id === id) {
          vm.form.name = ele.name
        }
      })
      vm.bootstrap_modal = new Modal(vm.$refs.deleteModal)
      vm.bootstrap_modal.show()
    },
    deleteNetwork (id) {
      const vm = this
      vm.bootstrap_modal.hide()
      network.delete(id, vm.token).then((res) => {
        if (res.status <= 201 && res.data.status === 'success') {
          vm.getNetwork()
          vm.AlertsInfo({
            state: 'success',
            title: i18n.t('__success'),
            info: i18n.t('__deleteSuccess')
          })
          vm.Alerted()
          vm.bootstrap_modal.hide()
        } else {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errMsg.toString()
          })
          vm.Alerted()
        }
        vm.Loaded()
      })
    },
    initBootstrapTooltip () {
      this.$nextTick(function () {
        this.bootstrap_tooltip = [].slice
          .call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
          .map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl, {
              trigger: 'hover'
            })
          })
        document
          .querySelectorAll('[data-bs-toggle="tooltip"]')
          .forEach((ele) => {
            ele.addEventListener('shown.bs.tooltip', function () {
              setTimeout(() => {
                Tooltip.getInstance(ele).hide()
              }, 600)
            })
          })
      })
    }
  },
  created () {},
  mounted () {
    if (this.userInfo) {
      this.getNetwork()
    }
  }
}
</script>
